@import url("https://fonts.googleapis.com/css2?family=Antic&family=Bellota+Text:wght@300;400;700&display=swap");
body {
  font-family: "Antic", sans-serif;
}
.--pink-bg {
  background-color: #ed62a3 !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
body {
  overflow-x: hidden;
}
.content {
  max-width: 2220px;
  margin: 0 auto;
  font-family: "Bellota Text", cursive;
  position: relative;
  min-height: 100vh;
}
.content.--orange-bg {
  position: relative;
}
.content.--orange-bg::before {
  content: "";

  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  margin-left: -50vw;
  background-color: #f9b232;
  z-index: -1;
}
.content.--pink-bg::before {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  margin-left: -50vw;
  background-color: #ed62a3;
  z-index: -1;
}
.content.--pink-bg .header__wrapper {
  background-color: #ed62a3;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f9b232;
  z-index: 1;
}
.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header.--fashion {
  background-color: #ffffff;
}
.logo {
  padding: 5px;
  height: 80px;
}
.logo__container {
  height: 100%;
}
.logo__link {
  display: block;
  height: 100%;
}
.logo__link img {
  display: block;
  height: 100%;
}
.logo.--portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  background-color: #f9b232;
  margin: 0 auto;
}
.logo.--portfolio .logo__link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo.--portfolio .logo__link img {
  max-width: 100%;
  height: auto;
}
.main-menu__nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-menu__item {
  margin: 20px 30px;
}
.main-menu__link {
  color: #000000;
  letter-spacing: 0.5px;
  font-size: 18px;
  text-transform: uppercase;
}
.gallery {
  padding-top: 120px;
  background-color: #f9b232;
}

.--commercial .gallery {
  padding-top: 190px;
}

.item {
  opacity: 1;
  cursor: pointer;
}

.item img {
  display: block;
  width: 100%;
}

.item span {
  position: absolute;
  top: 100%;
  transform: translateY(0);
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  padding: 5px 5px 5px 5px;
  text-align: center;
  color: #e51877;
  opacity: 0;
  line-height: 1.2;
  transition: 0.3s;
}

.item:hover span {
  opacity: 1;
  transform: translateY(-100%);
}

.item:nth-of-type(1) {
  order: 1;
}
.item:nth-of-type(2) {
  order: 2;
}
.item:nth-of-type(3) {
  order: 3;
}
.item:nth-of-type(4) {
  order: 4;
}

.portfolio-main {
  max-width: 1440px;
  margin: 0 auto;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.portfolio-menu ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.portfolio-menu li {
  padding: 5px 0;
}

nav {
  height: 90px;
  width: 90px;
  order: -1;
}

#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 36px;
  left: 30px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #000000;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383f;
}
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100vw;
  height: 100vh;
  box-shadow: 0 0 10px #85888c;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 50px;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  background-color: #ed62a3;
}

#menu li {
  padding: 40px 0;
  transition-delay: 2s;
}

#menu li a {
  font-size: 18px;
  letter-spacing: -0.5px;
  color: #000000;
  text-transform: uppercase;
}

#menuToggle input:checked ~ ul {
  transform: none;
}

.about {
  padding: 90px 15px 0 15px;
}

.about p {
  margin-bottom: 20px;
  text-align: justify;
}

.about p .bold {
  margin-bottom: 20px;
  text-align: justify;
  color: #f9b232;
  font-weight: bold;
}

.portfolio {
  margin-top: 90px;
}
.portfolio__container {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.portfolio__item {
  height: 100%;
  max-height: 380px;
}
.portfolio__item picture {
  display: block;
  height: 100%;
}
.portfolio__item picture img {
  height: 100%;
  max-width: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9b232;
}
.footer__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
  position: relative;
}
.footer span {
  padding: 10px 0;
}
.footer a {
  padding: 10px 0;
  color: #000000;
}
.--commercial .footer::before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -50vw;
  width: 100vw;
  height: 100%;
  background-color: #ffffff;
  z-index: -1;
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 40px 0;
}
.contact span {
  text-transform: uppercase;
  color: #ffcb14;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
}
.contact a {
  color: #000000;
  font-size: 14px;
  margin-bottom: 10px;
}

.--portfolio .header__wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
  max-width: 800px;
  margin: 0 auto;
}

.header__back {
  padding: 15px 0 10px 8px;
  position: relative;
}

.header__back img {
  height: 17px;
  margin-right: 5px;
}

.header__portfolio-item img {
  height: 65px;
}
.sub-gallery {
  background-color: #fff;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.sub-gallery::before {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 50%;
  margin-left: -50vw;
  z-index: -1;
}
.sub-gallery__item {
  min-height: 100vh;
}
.sub-gallery__wrapper {
  overflow-y: scroll;
  max-height: calc(100vh - 115px);
}

.--active {
  display: block !important;
}
.sub-gallery .header__wrapper {
  background-color: #fff;
}
.sub-gallery .header__back {
  padding: 40px 0;
  cursor: pointer;
}
.sub-gallery .header__back img {
  height: 30px;
}
.sub-gallery__top {
  display: grid;
  grid-template-columns: minmax(155px, 470px) minmax(40%, 1fr);
  margin-bottom: 10px;
  grid-gap: 20px;
}
.top__img img {
  display: block;
  width: 100%;
}
.top__txt h2 {
  color: #f9b232;
  font-size: 15px;
  margin-bottom: 20px;
}
.top__txt span {
  font-size: 14px;
}
.top__txt p {
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: bold;
}

.commercial-top__wrapper,
.commercial-video__wrapper {
  max-width: 800px;
  padding: 120px 15px;
  margin: 0 auto;
}
.commercial-video__aside {
  color: #f9b232 !important;
  margin-bottom: 5px !important;
}
.commercial-top__item {
  position: relative;
  display: block;
  margin-bottom: 15px;
}
.commercial-top__item img {
  display: block;
  width: 100%;
}
.commercial-top__item div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
}
.commercial-top__item span {
  display: block;
  color: #e51877;
  text-align: center;
}
.commercial-video {
  position: relative;
}
.commercial-video::before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -50vw;
  width: 100vw;
  height: 100%;
  background-color: #e51877;
  z-index: -1;
}
.commercial-video__item {
  width: 100%;
}
.commercial-video__item span {
  color: #f9b232;
  margin-bottom: 10px;
  display: block;
}
.commercial-video__item iframe {
  width: 100%;
  min-height: 210px;
  margin-bottom: 20px;
}

.header__description {
  display: flex;
  flex-direction: column;
  color: #e51877;
  padding: 10px 15px;
}

.column.--commercial {
  max-width: 1140px;
  margin: 0 auto;
}

.about__video span {
  display: block;
  text-align: center;
  color: #f9b232;
  font-weight: bold;
}

@media (min-width: 440px) {
  .commercial-video__item iframe {
    min-height: 260px;
  }
}

@media (min-width: 600px) {
  .header {
    position: static;
    top: 0;
    width: 100%;
    height: auto;
  }
  .header__wrapper {
    justify-content: center;
    flex-direction: column;
  }
  #menuToggle span {
    display: none;
  }
  #menuToggle input {
    display: none;
  }
  nav {
    height: auto;
    width: 100%;
    order: 1;
  }
  #menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: static;
    width: 100%;
    height: auto;
    box-shadow: none;
    margin: 0;
    padding: 0;
    padding-top: 0;
    transform: translate(0);
    transition: transform 0 cubic-bezier(0.77, 0.2, 0.05, 1);
    background-color: transparent;
  }
  #menuToggle {
    display: flex;
    flex-direction: column;
    position: static;
  }
  #menu li {
    margin: 3px 11px;
    padding: 0;
    transition-delay: 0s;
  }
  .about {
    position: relative;
    display: grid;
    grid-template-columns: minmax(265px, 340px) minmax(265px, 340px);
    grid-gap: 70px;
    padding-top: 50px;
    justify-content: center;
    font-family: "Bellota Text", cursive;
  }
  .about p {
    padding: 5px 0;
  }
  .about .--pd_95 {
    padding-bottom: 95px;
  }
  .portfolio {
    margin-top: 0;
  }
  .footer__wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;
    width: 100%;
    max-width: 750px;
  }
  .footer span {
    padding: 0;
  }
  .footer a {
    padding: 0;
  }
  .top-border {
    height: 10px;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f06e50;
  }
  .contact {
    padding: 130px 0 0 0;
  }
  .logo {
    height: 330px;
  }
  .logo.--portfolio .logo__link img {
    max-width: 744px;
    max-height: 100%;
  }
  .portfolio-menu ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .gallery {
    padding-top: 140px;
  }
  .--portfolio .gallery {
    padding-top: 100px;
  }
  .header__back {
    margin-top: 20px;
  }
  .sub-gallery__top {
    grid-gap: 80px;
  }
  .commercial-top__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .commercial-top__item {
    max-width: 49%;
  }
  .commercial-video__item iframe {
    min-height: 435px;
  }
}

@media (min-width: 900px) {
  .logo {
    height: 430px;
  }
  .portfolio-menu li {
    padding: 5px 0;
    height: 90px;
  }
  .portfolio-menu li a {
    height: 100%;
    display: block;
  }
  .portfolio-menu li img {
    height: 100%;
  }
}
